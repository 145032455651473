<template>
  <div id="team">
    <div class="container">
      <!-- <h2>{{ team.heading }}</h2> -->

      <div class="team">
        <span
          class="person"
          v-for="member in team_members"
          :key="member.id"

        >
        <!-- :to="member.link_relative" -->
          <figure>
            <router-link :to="member.link_relative">
              <div
                class="alt-image"
                :style="{'background-image':`url(${member.acf.hover_image_url.url}`}"
                :alt="member.acf.hover_image_url.alt || 'Team member alt text not set'"
                v-aspect-ratio="407.15 / 363"
              >
                <img
                  v-if="member.featured_media_url"
                  :src="member.featured_media_url"
                  :alt="member.acf.hover_image_url.alt || 'Team member alt text not set'"
                  class="img-hover"
                />
              </div>
            </router-link>
            <figcaption>
              <router-link :to="member.link_relative">
                <span class="text-link"><span v-html="member.title.rendered" /></span>
              </router-link>
              <small v-text="member.acf.role" />
            </figcaption>
          </figure>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// import { shuffle } from 'lodash'
export default {
  props: {
    team_members: Array
  
  },
  created () {
    // console.log('team_members', this.team_members)
    // this.randomMembers = shuffle(this.team.team_members)
  }

}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/container";
@import "../styles/grid";
@import "../styles/text";

#team {
  /* margin-top: -150px; */
  /* padding-top: 150px; */
  @media (max-width: 800px) {
    /* margin-top: -83px; */
    /* padding-top: 83px; */
  }
}

.container {
  @extend %container;
  @extend %mobile-container;
  // margin-bottom: 110px;
  @media (max-width: 800px) {
    /* margin-bottom: 62px; */
  }
}



.team {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  /* margin-left: grid-pct(1); */
  width: grid-pct(12);
  @media (max-width: 800px) {
    flex-direction: column;
    @include m-margin(0.5);
  }
}

.person {
  margin-bottom: 5%;
  width: 30%;
  color: inherit;
  text-decoration: none;
  @media (max-width: 800px) {
    width: 100%;
  }
}
.person:nth-last-child(1) {
  margin-bottom: 110px;
  @media (max-width: 800px) {
    margin-bottom: 110px;
    small {
      margin-bottom: 0;
    }
  }
}

.person:nth-child(3n+2) {
  margin-left: 5%;
  margin-right: 5%;

  @media (max-width: 800px) {
    margin-left: 0%;
    margin-right: 0%;
  }
}

figure {
  margin: 0;
}

.alt-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  .img-hover {
    width: 100%;
    height: calc(100% + 2px);
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.4s;
    &:hover {
    opacity: 0;
    transition: opacity 0.4s;
    }
  }
}

@include theme('light') {
  .alt-image {
    background-color: $light-placeholder;
    @include theme-transition;
  }
  .text-link {
    @include theme-transition;
    color: black;
  }

  small {
    @include theme-transition;
    color: black;
  }
}

@include theme('dark') {
  .alt-image {
    @include theme-transition;
    background-color: $dark-placeholder;
  }
  .text-link {
    @include theme-transition;
    color: white;
  }
  small {
    @include theme-transition;
    color: white;
  }
}

@include link-text-hover-underline('figcaption .text-link span', 0px, inherit, inherit);

figcaption {
  margin-top: 24px;
  margin-bottom: 0;
  letter-spacing: -.01em;
  .text-link {
    display: inline-block;
    @include b1;
    margin-bottom: 6px;
    @media (max-width: 800px) {
      margin-bottom: 0px;
      @include m-b1;
    }
  }

  small {
    display: block;
    font-family: $font-family-mono;
    @include b3;
    font-size: px(14);
    line-height: px(16);
    letter-spacing: .1em;
    text-transform: uppercase;
    opacity: .5;
    @media (max-width: 800px) {
      display: block;
      margin-top: 4px;
      @include m-b3;
      line-height: px(17);
    }
  }

  @media (max-width: 800px) {
    margin-top: 24px;
    small {
      margin-bottom: 32px;
    }
  }
}
</style>
