<template>
  <div class="container">
    <h1 
      class="main-title" 
      v-if="title" 
      v-html="title" 
    />
    <div 
      class="content" 
      v-if="content" 
      v-html="content"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    content: String
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/container';
@import '../styles/grid';
@import '../styles/text';
@import "../styles/variables";
@import "../styles/buttons";
@import "../styles/theme";

.container {
  @extend %container;
  @extend %mobile-container;
  /* margin-top: 172px; */
  /* margin-bottom: 50px; */
  @media (max-width: 800px) {
    /* margin-top: 109px; */
    /* margin-bottom: 50px; */
  }
}

.main-title {
  display: inline !important;
  margin-top: 0;
  margin-bottom: 0;
  width: grid-pct(8);
  // font-size: 3rem;
  font-weight: normal;
  letter-spacing: -0.01em;
  @include t2;
  @media (max-width: 800px) {
    @include m-t2;
    @include m-margin(0.5);
  }
}

@include link-text-hover-underline('.content::v-deep a', 0px, inherit, inherit);
@media (max-width: 800px) {
  @include link-text-hover-underline('.content::v-deep a', 0px, inherit, inherit);
}

.content {
  margin-top: 0;
  margin-bottom: 0;
  width: grid-pct(8);

  @media (max-width: 800px) {
    width: 100%;
    /* @include m-t2; */
    @include m-margin(0.5);
  }
}
.content::v-deep a { 
  color: $red !important;
}

.content::v-deep h1 { 

    @include t2;
    @media (max-width: 800px) {
      @include m-t2;
      margin-bottom: 16px;
    }
  }

.content::v-deep h2 {
  @include t3;
  @media (max-width: 800px) {
    @include m-t3;
    margin-bottom: 16px;
  }
}




</style>
