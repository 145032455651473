<template>
  <div>
    <!-- Loading. -->
    <transition name="loading">
      <div v-if="!page || !works" class="loading">
        <Loader />
      </div>
    </transition>

    <!-- Content. -->
    <div 
      v-if="page" 
      class="page-box content work" 
      ref="work"
    >

      <!-- Header -->
      <StdTextHero
        class="StdTextHero"
        v-if="page.title.rendered"
        :title="page.title.rendered"
        :content="page.acf.short_text"
      />

      <!-- List of times -->
      <TeamList
        class="TeamList"
        :v-if="page.acf.team_members"
        :team_members="page.acf.team_members"
      />

      <!-- Footer -->
      <Contact2021 
        class="Contact2021"
        :contact="page.acf.contact" 
        v-if="page.acf.contact && page.acf.contact.contact_blurb && page.acf.contact.subtitle && page.acf.contact.title"
      />
      <SubscribeForm2021
        class="SubscribeForm2021"
      />
      <CopyrightFooter
        class="CopyrightFooter"
        v-if="copyright && copyright !== ''"
        :copyright="copyright"
      />

    </div>
  </div>
</template>

<script>
import FadeIn from '../mixins/FadeIn'
import MatchedRoute from '../mixins/MatchedRoute'
import Wordpress from '../mixins/Wordpress'
import Loader from '../components/Loader'
import { TweenLite } from 'gsap' // note due to an import bug tween max is not avalible
import { throttle } from 'lodash'

import SubscribeForm2021 from '../components/SubscribeForm2021'
import Contact2021 from '../components/Contact2021'
import StdTextHero from '../components/StdTextHero'
import TeamList from '../components/TeamList'
import CopyrightFooter from '../components/CopyrightFooter'

export default {
  mixins: [FadeIn, MatchedRoute, Wordpress],
  data () {
    return {
      show: true,
      page: null,
      primaryNavActive: false,
      bgOffset: 1,
      eventListenerScroll: null,
      eventListenerResize: null,
      scene: null,
      works: [],
      chosenCat: null,
      videoModalActive: false,
      video: {
        embed_url: '',
        width: 640,
        height: 360
      }
    }
  },
  computed: {
    copyright () {
      return this?.page?.acf?.copyright_footer?.copyright || ''
    }
  },
  methods: {
    async fetchPage () {
      let page = null
      if (this.$route.query.preview) {
        page = await this.$wp.getPagePreviewByPath(this.$matchedRoute.pathResolved)
      } else {
        page = await this.$wp.getPageByPath(this.$matchedRoute.pathResolved)
      }


      this.page = page
      // console.log(this.page)

      // Fade Page Content In After Tick.
      this.$nextTick(() => this.fadePageContentIn())

      if (this.scene === null) {
        this.scene = this.$scrollmagic.scene({
          triggerElement: 'body',
          triggerHook: 0,
          duration: document.documentElement.scrollHeight,
          reverse: true
        })
        this.$nextTick(() => {
          this.$scrollmagic.addScene(
            this.scene.setTween(
              TweenLite.fromTo(
                this.$refs.work,
                30,
                {
                  backgroundPositionY: '0%'
                },
                {
                  backgroundPositionY: `100%`
                }
              )
            )
            // .addIndicators() // this is a debug option for vue scroll magic
          )
          this.scene.duration('100%')
        })
      }
    },
    onScroll (evt) {
      try {
        this.scene.duration(document.body.scrollHeight - window.innerHeight)
      } catch (e) {
        console.warn('@catch ~expected warning not bug~', e)
      }
    },
    openModal (video) {
      this.video = video
      this.videoModalActive = true
    }
  },
  created () {
    // this.fetchWork()
    this.throttleOnScroll = throttle(this.onScroll.bind(this), 300)
  },
  mounted () {
    this.eventListenerScroll = this.$nextTick(() => {
      window.addEventListener('scroll', this.throttleOnScroll, {
        passive: true
      })
    })
    this.eventListenerResize = this.$nextTick(() => {
      window.addEventListener('resize', this.throttleOnScroll, {
        passive: true
      })
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.eventListenerScroll)
    window.removeEventListener('resize', this.eventListenerResize)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.fetchPage())
  },
  components: {
    Loader,
    SubscribeForm2021,
    Contact2021,
    StdTextHero,
    TeamList,
    CopyrightFooter
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/container';
  @import '../styles/buttons';
  @import '../styles/grid';
  @import "../styles/text";
  @import "../styles/variables";

  .page-box {
    padding-top: 175px;
    padding-bottom: 110px;

    @media (max-width: 800px) {
      padding-top: 110px;
    }
  }

  /* .double-bottom { */
    /* Overiding the default */
    /* margin-bottom: 100px;
    @media (max-width: 800px) {
      margin-bottom: 50px;
    }
  } */

  .work {
    background-image: url('../assets/team/bg.svg');
    background-position: top left;
    background-attachment: fixed;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: 266.3px 1230.28px;
    @media (max-width: 800px) {
      background-image: none;
    }
  }

  .loading {
    @include loading;
  }
  @include loading-page-fade;

  .TeamList {
    margin-top: 100px;
    margin-bottom: -110px;
    @media (max-width: 800px) {
      margin-top: 50px;
    }
  }

  .Contact2021 {
    margin-top: 130px;
    @media (max-width: 800px) {
      margin-top: 110px;
    }
  }

  .SubscribeForm2021 {
    margin-top: 60px;
  }
  
  .CopyrightFooter {
    margin-top: calc(60px - 10px);
  }


</style>
